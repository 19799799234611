<template>
  <div>
    <div class="mb-4 topbar">
      <div>
        <h2><b-link :to="{ name: 'designer' }">Layout Studio</b-link></h2>
        <h2 class="m-2">&raquo;</h2>
        <h2>{{ layout.title }}</h2>
      </div>
      <b-button-toolbar class="toolbar">
        <b-button-group size="sm" class="mx-4">
          <b-button variant="primary" size="sm" v-b-toggle.sidebar-records>
            <BIconCircle /> {{ sidebarState ? 'Hide' : 'Show' }} Records Panel
          </b-button>
          <AddToPrintModal ref="addToPrintModal" @onSuccess="navToPrintSet" />
        </b-button-group>
        <b-button-group size="sm">
          <b-button class="mx-1" variant="primary" @click="onShuffle" :disabled="!filled">
            <BIconShuffle /> Shuffle Layout
          </b-button>
          <b-btn class="mx-1" variant="primary" @click="onRandomPopulate"
            :disabled="!records.length || filled === slots.flat().length">
            <BIconBoxArrowInDownRight /> Fill Empty Slots
          </b-btn>
          <b-btn class="mx-1" variant="primary" @click="onResetAll" :disabled="!filled">
            <BIconArrowCounterclockwise /> Reset All
          </b-btn>
        </b-button-group>
        <b-button-group size="sm" class="mx-4">
          <b-button variant="primary" size="sm" @click="openAddToPrinterModal" :disabled="!filled">
            <BIconPrinter /> Add to Print Set
          </b-button>
          <AddToPrintModal ref="addToPrintModal" @onSuccess="navToPrintSet" />
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-container fluid class="studio">
      <b-sidebar id="sidebar-records" title="Records" shadow v-model="sidebarState" :left="!sidebarPlacement"
        :right="sidebarPlacement">
        <template #header="{ hide }">
          <div :style="{ flex: 1 }">
            <div>
              <b-button @click="hide()" class="float-right" variant="link">
                <BIconX />
              </b-button>
              <b-button @click="sidebarPlacement = !sidebarPlacement" class="float-right" variant="link">
                <BIconBoxArrowRight v-if="!sidebarPlacement" />
                <BIconBoxArrowLeft v-if="sidebarPlacement" />
              </b-button>
              <h4>
                <span>Records: {{ records.length }}</span> <br />
                <span class="text-center">Slots: {{ remaining }} / {{ filled }}</span>
              </h4>
            </div>
            <div v-if="records.length">
              <b-input-group class="mt-3 p-2">
                <b-form-input id="searchRecords" placeholder="Search" v-model.trim="search"></b-form-input>
                <b-input-group-append>
                  <b-button variant="primary" :disabled="search === ''" @click="() => {
                    search = '';
                  }
                    ">
                    <BIconX />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </template>
        <Records :slots="slots" :filter="search" :records="records" @onAddRecord="onAddRecord"
          @onRemoveRecord="onRemoveRecord" />
      </b-sidebar>
      <JukeboxLayout v-model="slots" :headings="layout.headings" :panels="layout.panels"
        :colsPerPanel="layout.colsPerPanel" :recordsPerColumn="layout.recordsPerColumn" @onRemoveRecord="onRemoveRecord"
        @onReorder="onReorder" :color="layout.color" :titleStrip="layout.titleStrip" />
    </b-container>
  </div>
</template>

<script>
import JukeboxLayout from "./jukebox";
import Records from "./records";
import makeRequest from "@/requests/makeRequest";
import { GET_ALL45 } from "@/queries";
import AddToPrintModal from "@/components/inputs/addToPrintModal";
import { BIconShuffle, BIconBoxArrowInDownRight, BIconArrowCounterclockwise, BIconPrinter, BIconCircle, BIconX, BIconBoxArrowLeft, BIconBoxArrowRight } from "bootstrap-vue";
import router from '@/router';

import {
  findFirstObjectWithNullId,
  chunkArray,
  shuffleArray,
  findIndexById,
  findIndexesInChunkedArray,
  deepShuffleArray,
} from "./utils"; // Import the missing function

export default {
  name: "LayoutStudio",
  data() {
    return {
      sidebarPlacement: true,
      sidebarState: true,
      records: [],
      totalCount: undefined,
      slots: [[]],
      search: "",
    };
  },
  props: {
    layout: {
      type: Object,
    },
    slotsData: {
      type: Array,
    },
  },
  mounted() {
    this.resetAll();
    makeRequest(GET_ALL45).then((res) => {
      this.records = res.data.data.records;
    });
    this.slotsData.forEach((slot) => {
      this.onAddRecord(slot.record, slot.position, false);
    });
  },
  methods: {
    navToPrintSet(id) {
      router.push({ name: 'printSet', params: { id } })
    },
    onAddRecord(record, position = undefined, allowEmit = true) {
      let indexCoords = undefined
      if (position) {
        indexCoords = findIndexesInChunkedArray(this.slots, position);
      } else {
        indexCoords = findFirstObjectWithNullId(this.slots); // Use the imported function
      }

      if (indexCoords) {
        const { chunkIndex, itemIndex } = indexCoords;
        this.$set(this.slots[chunkIndex], itemIndex, record);
        if (allowEmit) this.$emit("onChangeLayout", this.slots);
      }
    },
    onRemoveRecord(id) {
      const res = findIndexById(this.slots, id);
      const { chunkIndex, itemIndex } = res;
      this.$set(this.slots[chunkIndex], itemIndex, { _id: null });
      this.$emit("onChangeLayout", this.slots);
    },
    onReorder() {
      this.$emit("onChangeLayout", this.slots);
    },
    onResetAll() {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to reset this layout? This will remove all records from this layout.`, {
        cancelVariant: 'outline-primary',
        okVariant: 'danger',
        title: 'Reset All',
      })
        .then((response) => {
          if (response) {
            this.resetAll()
            this.$emit("onChangeLayout", []);
          }
        })
    },
    openAddToPrinterModal() {
      this.$refs.addToPrintModal.show(this.slots, this.layout.titleStrip)
    },
    resetAll() {
      this.slots = chunkArray(
        Array((this.layout.panels * this.layout.colsPerPanel) * this.layout.recordsPerColumn).fill({ _id: null }),
        this.layout.recordsPerColumn
      );
    },
    onShuffle() {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to shuffle this layout? This will randomly reposition all the records within the layout.`, {
        cancelVariant: 'outline-primary',
        okVariant: 'danger',
        title: 'Shuffle Layout',
      })
        .then((response) => {
          if (response) this.slots = deepShuffleArray([...this.slots]);
          this.$emit("onChangeLayout", this.slots);
        })

    },
    onRandomPopulate() {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to fill the empty slots on this layout? This will add random records to the remaining empty slots in this layout.`, {
        cancelVariant: 'outline-primary',
        okVariant: 'danger',
        title: 'Fill Empty Slots',
      })
        .then((response) => {
          if (response) {
            // Get list of  ids of records alreadt in the layout
            const usedIds = this.slots.flat().filter(slot => slot && slot._id).map(slot => slot._id);
            // Filter the original records array to exclude usedIds
            const availableRecords = this.records.filter(record => !usedIds.includes(record._id));

            const shuffledArray = shuffleArray([...availableRecords]);
            let tempArray = [...this.slots];
            for (let i = 0; i < this.slots.length; i++) {
              for (let j = 0; j < this.slots[i].length; j++) {
                if (tempArray[i][j]._id === null)
                  if (shuffledArray.length > 0) tempArray[i][j] = shuffledArray.pop(); // Make sure there are still records left to add
              }
            }
            this.slots = tempArray;
            this.$emit("onChangeLayout", this.slots);
          }
        })
    },
  },
  computed: {
    remaining() {
      return this.slots.flat().filter((record) => record._id === null).length;
    },
    filled() {
      return this.slots.flat().filter((record) => record._id !== null).length;
    }
  },
  components: {
    JukeboxLayout,
    Records,
    BIconShuffle,
    BIconBoxArrowInDownRight,
    BIconArrowCounterclockwise,
    BIconCircle,
    BIconX,
    BIconPrinter,
    AddToPrintModal,
    BIconBoxArrowLeft,
    BIconBoxArrowRight
  },
};
</script>
<style scoped>
.studio {
  max-width: 100% !important;
}

h2,
.toolbar {
  display: inline;
}

.topbar {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
