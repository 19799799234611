var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-alert',{staticClass:"m-3",attrs:{"variant":"warning","show":!_vm.records.length}},[_vm._v("You have no records; Goto "),_c('b-link',{attrs:{"to":{ name: 'add' }}},[_vm._v("Add Records")]),_vm._v(" to add your first one.")],1),(_vm.records.length)?_c('div',[_c('div',{staticClass:"scroll"},_vm._l((_vm.filteredRecords),function(song,index){return _c('div',{key:`records_${index}`},[(_vm.artistIndexes.includes(index))?_c('h5',{staticClass:"mb-0 mt-4 artist"},[_vm._v(" "+_vm._s(song.artist)+" ")]):_vm._e(),_c('div',{staticClass:"blockrow"},[_c('div',{staticClass:"block",class:{ added: _vm.isAdded(song._id) },attrs:{"id":song._id},on:{"click":() => {
            if (_vm.isAdded(song._id)) {
              _vm.onRemoveRecord(song._id);
            } else {
              _vm.addRecord(song);
            }
          }}},[_c('div',{staticClass:"float-right"},[_vm._v(_vm._s(song.year))]),_c('div',{staticClass:"ml-3"},[_c('strong',[_vm._v("A:")]),_vm._v(" "+_vm._s(song.side.a.title)+" ")]),(song.side.b)?_c('div',{staticClass:"ml-3"},[_c('strong',[_vm._v("B:")]),_vm._v(" "+_vm._s(song.side.b.title)+" ")]):_vm._e(),(song.discogs && song.discogs.genres)?_c('div',{staticClass:"ml-3"},_vm._l((song.discogs.genres),function(genres,index){return _c('b-badge',{key:index,staticClass:"mr-1 mb-1",attrs:{"variant":"success"}},[_vm._v(_vm._s(genres))])}),1):_vm._e()]),(_vm.isAdded(song._id))?_c('b-button',{attrs:{"variant":"light-primary"},on:{"click":function($event){return _vm.onRemoveRecord(song._id)}}},[_c('BIconTrash')],1):_c('b-button',{attrs:{"variant":"light-primary","disabled":_vm.remaining === 0},on:{"click":function($event){return _vm.addRecord(song)}}},[_c('BIconPlusCircle')],1)],1)])}),0)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }