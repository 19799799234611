var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"align",attrs:{"fluid":""}},[_c('div',{staticClass:"list",style:({
    gridTemplateColumns: `repeat(${this.panels * this.colsPerPanel}, 214px)`,
  })},[_vm._l((_vm.visibleHeadings),function(heading,headIndex){return _c('h3',{key:headIndex,style:({
      backgroundColor: _vm.color,
      gridColumn: `span ${_vm.colsPerPanel}`,
    }),domProps:{"textContent":_vm._s(heading || '\u00a0')}})}),_vm._l((_vm.localValue),function(group,groupIndex){return _c('div',{key:`group-${groupIndex}`,staticClass:"column"},[_c('draggable',{attrs:{"group":"people","move":_vm.handleMove,"ghost-class":"grid-move"},on:{"end":_vm.handleDragEnd},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},[_c('transition-group',{attrs:{"tag":"div","id":`group-${groupIndex}`,"group-id":groupIndex}},_vm._l((group),function(song,songIndex){return _c('div',{key:songIndex,staticClass:"cell"},[_c('span',{staticClass:"cardAddress A",style:({ color: _vm.primaryColor })},[_vm._v(" "+_vm._s(_vm.numberToLetter(groupIndex) + (songIndex * 2 + 1).toString().slice(-1))+" ")]),_c('span',{staticClass:"cardAddress B",style:({ color: _vm.primaryColor })},[_vm._v(" "+_vm._s(_vm.numberToLetter(groupIndex) + (songIndex * 2 + 2).toString().slice(-1))+" ")]),(song && song?._id !== null)?_c('div',{staticClass:"card-container"},[_c('Card',{staticClass:"card",class:{
                  dropzone:
                    _vm.swappable.toGroup === groupIndex &&
                    _vm.swappable.toIndex === songIndex,
                },attrs:{"artist":song.artist,"sideA":song.side.a.title,"sideB":song.side.b.title,"record":song,"toolbar":_vm.toolbarOptions,"customize":_vm.titleStrip}})],1):_c('div',{staticClass:"blank",class:{
              dropzone:
                _vm.swappable.toGroup === groupIndex &&
                _vm.swappable.toIndex === songIndex,
            },style:({ borderColor: _vm.primaryColor })})])}),0)],1)],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }