<template>
  <b-container fluid class="align">
    <div class="list" :style="{
      gridTemplateColumns: `repeat(${this.panels * this.colsPerPanel}, 214px)`,
    }">
      <h3 v-for="(heading, headIndex) in visibleHeadings" v-text="heading || '\u00a0'" :key="headIndex" :style="{
        backgroundColor: color,
        gridColumn: `span ${colsPerPanel}`,
      }" />
      <div v-for="(group, groupIndex) in localValue" v-bind:key="`group-${groupIndex}`" class="column">
        <draggable group="people" v-model="localValue" :move="handleMove" @end="handleDragEnd" ghost-class="grid-move">
          <transition-group tag="div" :id="`group-${groupIndex}`" :group-id="groupIndex">
            <div v-for="(song, songIndex) in group" v-bind:key="songIndex" class="cell">
              <span class="cardAddress A" :style="{ color: primaryColor }">
                {{
                  numberToLetter(groupIndex) +
                  (songIndex * 2 + 1).toString().slice(-1)
                }}
              </span>
              <span class="cardAddress B" :style="{ color: primaryColor }">
                {{
                  numberToLetter(groupIndex) +
                  (songIndex * 2 + 2).toString().slice(-1)
                }}
              </span>
              <div class="card-container" v-if="song && song?._id !== null">
                <Card class="card" :artist="song.artist" :sideA="song.side.a.title" :sideB="song.side.b.title"
                  :record="song" :toolbar="toolbarOptions" :customize="titleStrip" :class="{
                    dropzone:
                      swappable.toGroup === groupIndex &&
                      swappable.toIndex === songIndex,
                  }"></Card>
              </div>

              <div class="blank" :style="{ borderColor: primaryColor }" :class="{
                dropzone:
                  swappable.toGroup === groupIndex &&
                  swappable.toIndex === songIndex,
              }" v-else></div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
  </b-container>
</template>

<script>
import Card from "@/components/45/Card";
import draggable from "vuedraggable";

export default {
  name: "JukeboxLayout",
  props: {
    titleStrip: {
      type: Object,
      default: null,
    },
    headings: {
      type: Array,
    },
    color: {
      type: String,
      default: '#2ca8c7',
    },
    panels: {
      type: Number,
      default: 5,
    },
    colsPerPanel: {
      type: Number,
      default: 2,
    },
    recordsPerColumn: {
      type: Number,
      default: 5,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      swappable: {
        fromGroup: null,
        toGroup: null,
        fromIndex: null,
        toIndex: null,
      },
      toolbarOptions: [
        {
          name: "trash",
          icon: "trash",
          onClick: (record) => {
            this.onRemoveRecord(record._id)

          },
        },

      ]

    };
  },
  mounted() {
    this.localValue = this.value;
  },
  methods: {
    handleDragEnd() {
      const movingItem =
        this.localValue[this.swappable.fromGroup][this.swappable.fromIndex];
      const futureItem =
        this.localValue[this.swappable.toGroup][this.swappable.toIndex];
      const _items = Object.assign([], this.localValue);
      _items[this.swappable.fromGroup][this.swappable.fromIndex] = futureItem;
      _items[this.swappable.toGroup][this.swappable.toIndex] = movingItem;
      this.localValue = _items;
      this.swappable.toGroup = null;
      this.swappable.toIndex = null;
      this.$emit("onReorder");
    },
    handleMove(e) {
      const { index, futureIndex } = e.draggedContext;
      this.swappable.fromGroup = parseInt(e.from.getAttribute("group-id"));
      this.swappable.toGroup = parseInt(e.to.getAttribute("group-id"));
      if (futureIndex >= this.recordsPerColumn) return false;
      this.swappable.toIndex = futureIndex;
      this.swappable.fromIndex = index;
      return false; // disable sort
    },
    numberToLetter(number) {
      // "A".charCodeAt(0) === 65
      return String.fromCharCode(65 + number);
    },
    onRemoveRecord(id) {
      this.$emit("onRemoveRecord", id);
    },
  },
  computed: {
    primaryColor() {
      if (this.titleStrip && this.titleStrip.primaryColor) {
        return this.titleStrip.primaryColor;
      } else if (this.$store.getters.getUser.meta.defaultTitleStrip.primaryColor) {
        return this.$store.getters.getUser.meta.defaultTitleStrip.primaryColor;
      } else if (this.color) { // these next two will probably never happen
        return this.color;
      } else {
        return '#E622222';
      }
    },
    visibleHeadings() {
      const totalColumns = this.colsPerPanel * this.headings.length;
      const numVisibleHeadings = Math.min(this.panels, this.headings.length);

      if (totalColumns > (this.panels * this.colsPerPanel)) {
        return this.headings.slice(0, numVisibleHeadings);
      } else {
        const extraColumns = (this.panels * this.colsPerPanel) - totalColumns;
        const extraHeadings = Math.ceil(extraColumns / this.colsPerPanel);
        return [...this.headings, ...Array(extraHeadings).fill(null)];
      }
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newLocalValue) {
      this.$emit("input", newLocalValue);
    },
  },
  components: {
    Card,
    draggable,
  },
};
</script>

<style scoped>
.list {
  display: grid;
  grid-column-gap: 15px;
}

.cell {
  height: 72px;
  position: relative;
  cursor: all-scroll;
  margin-bottom: 10px;
  border: 2px dashed transparent;
}

.cell.sortable-drag .cardAddress {
  display: none;
}

.card {
  transform: scale(0.74);
  transform-origin: top left;
  border-radius: 0;
}

h3 {
  text-align: center;
  background-color: rgb(44, 168, 199);
  color: #fff;
  border-radius: 8px;
  padding: 10px;
}

.blank {
  border: 2px dashed;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.card-container {
  position: relative;
}

.dropzone {
  border-color: black;
  background-color: #eee;
  opacity: 0.5;
  box-shadow: 0 0 10px 0 rgba(255, 238, 7, 0.664);
}

.cardAddress {
  position: absolute;
  z-index: 2;
  font-size: 20px;
  font-weight: bold;
  pointer-events: none;
}

.cardAddress.A {
  top: 2px;
}

.cardAddress.B {
  bottom: -2px;
}

.list .column:nth-child(even) .cardAddress {
  right: 5px;
}

.list .column:nth-child(odd) .cardAddress {
  left: 5px;
}
</style>
