<template>
  <div>
    <b-alert variant="warning" class="m-3" :show="!records.length">You have no records; Goto <b-link
        :to="{ name: 'add' }">Add
        Records</b-link> to add your first one.</b-alert>
    <div v-if="records.length">

      <div class="scroll">
        <div v-for="(song, index) in filteredRecords " v-bind:key="`records_${index}`">
          <h5 v-if="artistIndexes.includes(index)" class="mb-0 mt-4 artist">
            {{ song.artist }}
          </h5>
          <div class="blockrow">
            <div :id="song._id" class="block" @click="() => {
              if (isAdded(song._id)) {
                onRemoveRecord(song._id);
              } else {
                addRecord(song);
              }
            }" :class="{ added: isAdded(song._id) }">
              <div class="float-right">{{ song.year }}</div>
              <div class="ml-3">
                <strong>A:</strong>
                {{ song.side.a.title }}
              </div>
              <div class="ml-3" v-if="song.side.b">
                <strong>B:</strong>
                {{ song.side.b.title }}
              </div>
              <div class="ml-3" v-if="song.discogs && song.discogs.genres">
                <b-badge v-for="(genres, index) in song.discogs.genres" variant="success" v-bind:key="index"
                  class="mr-1 mb-1">{{ genres }}</b-badge>
              </div>
            </div>

            <b-button variant="light-primary" v-if="isAdded(song._id)" @click="onRemoveRecord(song._id)">
              <BIconTrash />
            </b-button>
            <b-button variant="light-primary" @click="addRecord(song)" :disabled="remaining === 0" v-else>
              <BIconPlusCircle />
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconTrash, BIconPlusCircle } from "bootstrap-vue";
export default {
  name: "DesignerComponent",
  props: {
    records: {
      type: Array,
      required: true,
    },
    filter: {
      type: String,
    },
    slots: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      totalCount: undefined,
    };
  },
  methods: {
    isAdded: function (id) {
      return this.slots
        .flat()
        .map((record) => (record && record._id === id ? true : false))
        .includes(true);
    },
    addRecord: function (record) {
      if (this.isAdded(record._id)) {
        console.log(`already added ${record._id}`);
      } else {
        this.$emit("onAddRecord", record);
      }
    },
    onRemoveRecord(id) {
      this.$emit("onRemoveRecord", id);
    },
  },

  computed: {
    remaining() {
      return this.slots.flat().filter((record) => record._id === null).length;
    },
    artistIndexes() {
      const indexes = [];
      let currentArtist = null;

      this.filteredRecords.forEach((song, index) => {
        if (song.artist !== currentArtist) {
          indexes.push(index);
          currentArtist = song.artist;
        }
      });

      return indexes;
    },
    filteredRecords() {
      const searchTerm = this.filter.toLowerCase();
      return this.records.filter(
        (song) =>
          song.artist.toLowerCase().includes(searchTerm) ||
          (song.side.a.title &&
            song.side.a.title.toLowerCase().includes(searchTerm)) ||
          (song.side.b &&
            song.side.b.title &&
            song.side.b.title.toLowerCase().includes(searchTerm))
      );
    },
  },
  components: {
    BIconTrash, BIconPlusCircle
  },
};
</script>

<style scoped>
.artist {
  background-color: #fe8164;
  padding: 5px 10px;
  color: #fff;
}

.block {
  background-color: #fff;
  border: 1px solid #c9cdd1;
  padding: 0px 10px 0 2px;
  margin: 12px;
  cursor: pointer;
}

.block.added {
  opacity: 0.4;
}

.blockrow {
  display: grid;
  grid-template-columns: 1fr 42px;
  align-items: center;
  justify-content: center;
}
</style>
