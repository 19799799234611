<template>
  <div class="m-4" v-if="layout">
    <LayoutStudio :layout="layout" :slotsData="slots" @onChangeLayout="onChangeLayout" v-if="loaded"
      :style="{ overflowX: 'scroll', overflowY: 'hidden', height: `${(layout.recordsPerColumn * 100) + 100}px` }" />
    <div class="m-4" v-if="!layout">Loading...</div>
  </div>
</template>

<script>
import LayoutStudio from "@/components/designer/studio";
import makeRequest from "@/requests/makeRequest";
import { saveLayoutRecords } from "@/requests";
import { GET_LAYOUT, GET_LAYOUT_RECORDS } from "@/queries";

export default {
  name: "LayoutStudioPage",
  data() {
    return {
      loaded: false,
      slots: [],
      layout: null,
    };
  },
  mounted() {
    makeRequest(GET_LAYOUT, { id: this.$route.params.id }).then((res) => {
      this.layout = res.data.data.layout;
    });
    makeRequest(GET_LAYOUT_RECORDS, { id: this.$route.params.id }).then((res) => {
      this.slots = res.data.data.layoutRecords
      this.loaded = true
    });
  },
  methods: {
    onChangeLayout(updatedLayout) {
      saveLayoutRecords(this.layout._id, updatedLayout);
    },
  },
  components: {
    LayoutStudio,
  },
};
</script>